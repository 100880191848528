import axios from "axios";
import React, { useState } from "react";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`/login`, { userName, password });
      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        window.location.href = "/iciciMessage";
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="title">Login!</h2>
        <p className="subtitle">Please sign in to your account</p>

        <form className="login-form" onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="userName" className="sr-only">
              Username
            </label>
            <input
              id="userName"
              name="userName"
              type="text"
              required
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="input-field"
              placeholder="Username"
            />
          </div>
          <div className="input-group">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              placeholder="Password"
            />
          </div>

          <div className="options">
            <div className="remember-me">
              <input id="remember-me" name="remember-me" type="checkbox" />
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <a href="#" className="forgot-password">
              Forgot your password?
            </a>
          </div>

          <div className="submit-container">
            <button type="submit" className="login-btn">
              Sign In
            </button>
          </div>
        </form>
      </div>

      {/* Inline CSS for a premium, responsive, and creative design */}
      <style jsx>{`
        .login-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
          padding: 20px;
        }
        .login-box {
          width: 100%;
          max-width: 400px;
          padding: 40px;
          background: #fff;
          border-radius: 12px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        .title {
          font-size: 2.5rem;
          font-weight: 700;
          color: #333;
          margin-bottom: 10px;
        }
        .subtitle {
          color: #666;
          font-size: 1rem;
          margin-bottom: 20px;
        }
        .input-group {
          margin-bottom: 20px;
        }
        .input-field {
          width: 100%;
          padding: 12px;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 8px;
          transition: border-color 0.3s;
        }
        .input-field:focus {
          border-color: #2575fc;
          outline: none;
        }
        .options {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .remember-me {
          display: flex;
          align-items: center;
        }
        .remember-me input {
          margin-right: 5px;
        }
        .remember-me label {
          font-size: 0.9rem;
          color: #333;
        }
        .forgot-password {
          color: #2575fc;
          text-decoration: none;
          font-size: 0.9rem;
        }
        .forgot-password:hover {
          text-decoration: underline;
        }
        .submit-container {
          text-align: center;
        }
        .login-btn {
          width: 100%;
          padding: 12px;
          background: #2575fc;
          color: #fff;
          border: none;
          border-radius: 8px;
          font-size: 1rem;
          font-weight: bold;
          cursor: pointer;
          transition: background 0.3s;
        }
        .login-btn:hover {
          background: #1e63d4;
        }

        /* Responsive Design */
        @media (max-width: 768px) {
          .login-box {
            padding: 30px;
          }
          .title {
            font-size: 2rem;
          }
          .subtitle {
            font-size: 0.9rem;
          }
        }
        @media (max-width: 480px) {
          .login-box {
            padding: 20px;
          }
          .title {
            font-size: 1.8rem;
          }
          .subtitle {
            font-size: 0.8rem;
          }
        }
      `}</style>
    </div>
  );
};

export default Login;
