import React from "react";
import { useLocation } from "react-router-dom";

function ViewSms() {
  const location = useLocation();
  const smsArray = location.state || []; // Default to an empty array if state is undefined

  // Function to format the date in DD/MM/YYYY format
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const styles = {
    container: {
      padding: "20px",
      maxWidth: "1100px",
      margin: "0 auto",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#333",
      textAlign: "center",
    },
    smsContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
    },
    smsCard: {
      flex: "1 1 calc(100% - 20px)", // Full width on mobile by default
      padding: "20px",
      border: "1px solid #e0e0e0",
      borderRadius: "10px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      maxWidth: "100%",
      minHeight: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    smsText: {
      fontSize: "1.1rem",
      color: "#555",
      marginBottom: "10px",
    },
    smsSender: {
      fontSize: "0.9rem",
      color: "#777",
      fontStyle: "italic",
    },
    dateBadge: {
      backgroundColor: "#e0ffe0",
      color: "#2e7d32",
      fontWeight: "bold",
      padding: "5px 10px",
      borderRadius: "5px",
      display: "inline-block",
      marginTop: "10px",
    },
    senderBadge: {
      backgroundColor: "#d3d3ff",
      color: "#3f51b5",
      fontWeight: "bold",
      padding: "5px 10px",
      borderRadius: "5px",
      display: "inline-block",
      marginTop: "10px",
    },
    noSmsMessage: {
      textAlign: "center",
      color: "#888",
      fontSize: "1.2rem",
      marginTop: "20px",
    },
    // Responsive adjustments
    "@media (min-width: 600px)": {
      smsCard: {
        flex: "1 1 calc(48% - 20px)", // Two columns for tablet-sized devices
      },
    },
    "@media (min-width: 1024px)": {
      smsCard: {
        flex: "1 1 calc(32% - 20px)", // Three columns for larger screens
      },
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>All Latest SMS</h1>

      {smsArray.length === 0 ? (
        <p style={styles.noSmsMessage}>No SMS messages available.</p>
      ) : (
        <div style={styles.smsContainer}>
          {smsArray.map((sms, index) => (
            <div key={index} style={styles.smsCard}>
              <p style={styles.smsText}>{sms.body}</p>
              <p style={styles.smsSender}>{sms.customerName}</p>
              <p style={styles.dateBadge}>{formatDate(sms.date)}</p>
              {sms.address && (
                <p style={styles.senderBadge}>Sender: {sms.address}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ViewSms;
