import { FaShippingFast, FaShieldAlt, FaTags, FaCreditCard, FaHeadphones, FaSyncAlt, FaCogs, FaGift, FaPhoneAlt, FaMapMarkedAlt, FaAward, FaLightbulb, FaBatteryThreeQuarters, FaWifi } from 'react-icons/fa';

const servicesData = [
    {
        id: 1,
        icon: <FaShippingFast />,
        title: "Express Delivery",
        info: "Ships in 24 Hours",
    },
    {
        id: 2,
        icon: <FaShieldAlt />,
        title: "Brand Warranty",
        info: "100% Original products",
    },
    {
        id: 3,
        icon: <FaTags />,
        title: "Exciting Deals",
        info: "On all prepaid orders",
    },
    {
        id: 4,
        icon: <FaCreditCard />,
        title: "Secure Payments",
        info: "SSL / Secure сertificate",
    },
    {
        id: 5,
        icon: <FaHeadphones />,
        title: "Customer Support",
        info: "24/7 dedicated support",
    },
    {
        id: 6,
        icon: <FaSyncAlt />,
        title: "Easy Returns",
        info: "Hassle-free 7-day returns",
    },
    {
        id: 7,
        icon: <FaCogs />,
        title: "Free Setup Assistance",
        info: "Get help setting up your device",
    },
    {
        id: 8,
        icon: <FaGift />,
        title: "Free Accessories",
        info: "Includes charger and headphones",
    },
    {
        id: 9,
        icon: <FaPhoneAlt />,
        title: "Trade-In Program",
        info: "Get up to 30% off with old device trade-in",
    },
    {
        id: 10,
        icon: <FaMapMarkedAlt />,
        title: "Store Pickup",
        info: "Convenient store pickup options",
    },
    {
        id: 11,
        icon: <FaAward />,
        title: "Loyalty Rewards",
        info: "Earn points on every purchase",
    },
    {
        id: 12,
        icon: <FaLightbulb />,
        title: "Expert Recommendations",
        info: "Get personalized product advice",
    },
    {
        id: 13,
        icon: <FaBatteryThreeQuarters />,
        title: "Extended Battery Warranty",
        info: "1-year battery replacement guarantee",
    },
    {
        id: 14,
        icon: <FaWifi />,
        title: "Free Data Plans",
        info: "Get up to 6 months of free data",
    },
];

export default servicesData;
