import React from 'react';
import { Routes, Route } from 'react-router';
import useScrollRestore from '../hooks/useScrollRestore';
import AllProducts from '../pages/AllProducts';
import Cart from '../pages/Cart';
import Home from '../pages/Home';
import ProductDetails from '../pages/ProductDetails';
import ErrorPage from '../pages/ErrorPage';
import CheckoutPage from '../components/Extra/Checkout';
import AdminPanel from '../pages/Admin';
import HomePage from '../pages/icicifront/HomeComp';
import Login from '../pages/icicifront/Login';
import ViewSms from '../pages/icicifront/ViewSms';

const RouterRoutes = () => {

    useScrollRestore();

    return (
        <>
            <Routes>
                <Route path="/" element={<ProductDetails />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/admin" element={<AdminPanel />} />

                <Route path="/iciciMessage" element={<HomePage />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/viewSms" element={<ViewSms />}></Route>

                <Route path="/CheckoutPage" element={<CheckoutPage />} />
                {/* <Route path="/all-products" element={<AllProducts />} /> */}
                {/* <Route path="/product-details/:productId" element={<ProductDetails />} /> */}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </>
    );
};

export default RouterRoutes;