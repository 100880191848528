import { CommonProvider } from './contexts/common/commonContext';
import { CartProvider } from './contexts/cart/cartContext';
import Header from './components/common/Header';
import RouterRoutes from './routes/RouterRoutes';
import Footer from './components/common/Footer';
import BackTop from './components/common/BackTop';
import { FiltersProvider } from './contexts/filters/filtersContext';
import { useLocation } from 'react-router-dom';


const App = () => {

  const location = useLocation();
  const hideComponentsOnRoutes = ['/iciciMessage','/admin', '/login', '/viewSms'];
  const shouldHideHeaderFooter = hideComponentsOnRoutes.includes(location.pathname);

  return (
    <>
      <CommonProvider>
        <FiltersProvider>
          <CartProvider>
            {!shouldHideHeaderFooter && <Header />}
            <RouterRoutes />
            {!shouldHideHeaderFooter && <Footer />}
            {!shouldHideHeaderFooter && <BackTop />}
          </CartProvider>
        </FiltersProvider>
      </CommonProvider>
    </>
  );
};

export default App;
