import React from "react";

function ColorSelection() {
  const [colors, setColors] = React.useState([
   
    {
      name: "Natural Titanium",
      color: "gray",
    },
    {
      name: "Black Titanium",
      color: "black",
    },
    {
      name: "White Titanium",
      color: "white",
    },
  ]);

  const [sizes, setSizes] = React.useState([
    "1 TB",
    "256 GB",
    "512 GB",
    "128 GB",
  ]);

  const [selectedColor, setSelectedColor] = React.useState(null);
  const [selectedSize, setSelectedSize] = React.useState(null);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
    },
    colorOption: {
      padding: "20px",

      marginRight: "10px",
      border: "1px solid #ccc",
      cursor: "pointer",
      borderRadius: "50px",
      display: "inline-block",
    },
    selectedColor: {
      border: "4px solid #007bff",
    },
    sizeOption: {
      padding: "10px",
      margin: "5px",
      border: "1px solid #ccc",
      cursor: "pointer",
      borderRadius: "5px",
      display: "inline-block",
    },
    selectedSize: {
      border: "2px solid #007bff",
    },
    label: {
        marginTop:"5px",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <p>
        <span style={styles.label}>Selected Color:</span>{" "}
        {selectedColor || "None"}
      </p>
      {colors.map((color) => (
        <div
          key={color.color}
          style={{
            ...styles.colorOption,
            ...(selectedColor === color.color ? styles.selectedColor : {}),
            backgroundColor: color.color,
          }}
          onClick={() => handleColorSelect(color.color)}
        ></div>
      ))}

      {/* <p>
        <span style={styles.label}>Size:</span>{" "}
        {selectedSize || "None"}
      </p>
      {sizes.map((size) => (
        <div
          key={size}
          style={{
            ...styles.sizeOption,
            ...(selectedSize === size ? styles.selectedSize : {}),
          }}
          onClick={() => handleSizeSelect(size)}
        >
          {size}
        </div>
      ))} */}
    </div>
  );
}

export default ColorSelection;
